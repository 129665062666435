import React from 'react'
import {
  ContactMessageWrapper,
  ContactContainer,
  BottomBackground,
  QuotesImage,
  StampImage,
  BottomWrapper,
  ContactFormMessage,
  ContactFormMessageAnswer,
  BottomDivider,
} from '../components/CustomContact/ContactPage/styles'
import backgroundImage from '../images/home/rysunek.svg'
import quoteImg from '../images/contact/quotes.svg'
import stampImg from '../images/contact/stamp.svg'

const ThankYou = () => (
  <>
    <ContactContainer>
      <ContactMessageWrapper>
        <ContactFormMessage>Thank you for your message!</ContactFormMessage>
        <ContactFormMessageAnswer>
          We will answer as soon as it is possible.
        </ContactFormMessageAnswer>
      </ContactMessageWrapper>
      <StampImage src={stampImg} alt={''} />
    </ContactContainer>
    <BottomWrapper>
      <QuotesImage src={quoteImg} alt={''} />
      <BottomBackground src={backgroundImage} alt={''} />
      <BottomDivider />
    </BottomWrapper>
  </>
)

export default ThankYou
